import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import { Text } from "../../containers/language";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>

      <article className={styles.foot}>
        <p style={{ cursor: "default" }}>
          Europa{" "}
          <Link
            style={{ textDecoration: "none", color: "#fff", cursor: "default" }}
            to="/admin"
          >
            Bet
          </Link>{" "}
          2021
        </p>
        </article>

        <article className={styles.support}>
        <p>
          <Text tid="development"/>:{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://xakepa.github.io/Portfolio/"
          >
            <Text tid="author"/>
          </a>
        </p>
        </article>
      </div>
    </footer>
  );
};

export default Footer;
