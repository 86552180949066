import React from "react";
import styles from "./index.module.css";
import GlowingButton from "../glowing-button";
import { Text } from "../../containers/language";

const SingleBonus = ({
  imgUrl,
  articleTitle,
  address,
  altName,
  pathName,
  date,
}) => {
  return (
    <div className={styles.bonus}>

      <div className={styles.imgContainer}>
        <img className={styles.img} src={imgUrl} alt={altName} />
      </div>
      
      <p className={styles.title}>{articleTitle}</p>
      <span className={styles.address}>{address}</span>

      <div className={styles.actions}>
        <p className={styles.date}>{date}</p>
        <div className={styles.customButton}>
          <GlowingButton title={<Text tid="learnMore" />} path={pathName} />
        </div>
      </div>
    </div>
  );
};

export default SingleBonus;
