import React from "react";
import styles from "./index.module.css";
import moneyIcon from "../../images/money-icon.png";
import starIcon from "../../images/star-icon.png";
import drinkIcon from "../../images/drinks-icon.png";
import parkingIcon from "../../images/parking-icon.png";
import cardIcon from "../../images/cc-icon.png";
import wifiIcon from "../../images/wifi-icon.png";
import personIcon from "../../images/person-icon.png";

const Banner = () => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.iconsContainer}>
        
        <div className={styles.singleIconContainer}>
          <img src={moneyIcon} alt="moneyIcon" />
          <p>Джакпоти</p>
        </div>

        <div className={styles.singleIconContainer}>
          <img src={starIcon} alt="starIcon" />
          <p>Бонуси</p>
        </div>

        <div className={styles.singleIconContainer}>
          <img src={drinkIcon} alt="drinkIcon" />
          <p>Безплатни напитки</p>
        </div>

        <div className={styles.singleIconContainer}>
          <img src={parkingIcon} alt="parkingIcon" />
          <p>Безплатен паркинг</p>
        </div>
      </div>

      <div className={styles.iconsContainer}>
        <div className={styles.singleIconContainer}>
          <img src={starIcon} alt="starIcon" />
          <p>Съвременно оборудване</p>
        </div>

        <div className={styles.singleIconContainer}>
          <img src={cardIcon} alt="cardIcon" />
          <p>Пос терминал</p>
        </div>

          <div className={styles.singleIconContainer}>
            <img src={wifiIcon} alt="wifiIcon" />
            <p>Безплатен Wi-fi</p>
          </div>

        <div className={styles.singleIconContainer}>
          <img src={personIcon} alt="personIcon" />
          <p>Обслужване на ниво</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
