import React from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

const GlowingButton = ({ title, path }) => {
  return (
    <div className={styles.buttonWrapper}>
      <Link className={styles.button} to={path}>
        <button className={styles["glow-on-hover"]}>{title}</button>
      </Link>
    </div>
  );
};

export default GlowingButton;
