import ImageGallery from "react-image-gallery";
import "./image-gallery.css";
import styles from "./index.module.css";

const Gallery = ({ images, title }) => {
  return (
    <section
      style={{ background: "#000", padding: "40px 0", textAlign: "center" }}
    >

      <h1
        style={{
          color: "whitesmoke",
          borderBottom: "2px solid whitesmoke",
          display: 'inline-block',
          textTransform: "uppercase",
        }}
      >
        {title}
      </h1>

      <div className={styles.galleryContainer}
      >
        <ImageGallery
          showBullets={true}
          showFullscreenButton={false}
          lazyLoad={true}
          items={images}
        />
        ;
      </div>
    </section>
  );
};

export default Gallery;
