import React from "react";
import styles from "./index.module.css";

const GoogleMap = ({ title, googleUrl, width, height, loading }) => {
  return (
    <div className={styles["map-responsive"]}>
      <iframe
        title={title}
        src={googleUrl}
        width={width}
        height={height}
        style={{ border: 0 }}
        allowFullScreen=""
        loading={loading}
      ></iframe>
    </div>
  );
};

export default GoogleMap;
