import { WidgetLoader, Widget } from "react-cloudinary-upload-widget";
import { useState } from "react";

const button = {
  color: "white",
  border: "none",
  width: "300px",
  backgroundColor: "green",
  borderRadius: "8px",
  height: "40px",
  fontSize: "22px",
};

const CloudinaryUploadWidget = () => {
  const [url, setUrl] = useState([]);
  const [imageReady, setImageReady] = useState(false);
  const [name, setName] = useState([]);

  const successCallBack = (result) => {

    let folderName = result.info.public_id.split('/');

    const thumbnail = `https://res.cloudinary.com/dedg9uwnn/image/upload/c_thumb,w_300/v${result.info.version}/${result.info.public_id}.${result.info.format}`;
    setName(folderName[0]);
    setImageReady(true);
    setUrl(thumbnail);
  };

  const failureCallBack = (result) => alert(`Снимката не се качи!!`);

  return (
    <div
      style={{
        background: "#190F15",
        display: "flex",
        flexFlow: "column wrap",
        alignItems: "center",
        height: "70vh",
        paddingTop: "15px",
        justifyContent: "space-between",
      }}
    >
      <WidgetLoader />
      <Widget
        sources={[
          "local",
          "camera",
          "facebook",
          "instagram",
          "image_search",
          "google_drive",
          "dropbox",
        ]} // set the sources available for uploading -> by default
        // all sources are available. More information on their use can be found at
        // https://cloudinary.com/documentation/upload_widget#the_sources_parameter

        resourceType={"auto"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
        cloudName={"dedg9uwnn"} // your cloudinary account cloud name.
        // Located on https://cloudinary.com/console/
        uploadPreset={"f2azyoh2"} // check that an upload preset exists and check mode is signed or unisgned
        buttonText={"БОТЕВАТА"} // default 'Upload Files'
        style={button} // inline styling only or style id='cloudinary_upload_button'
        folder={"Botevata"} // set cloudinary folder name to send file
        cropping={true} // set ability to crop images -> default = true
        onSuccess={successCallBack}
        onFailure={failureCallBack}
        logging={true} // logs will be provided for success and failure messages,
        // set to false for production -> default = true
        // To use the file name as the public_id use 'use_filename={true}' parameter
        use_filename={false} // tell Cloudinary to use the original name of the uploaded
        // file as its public ID -> default = true,
      />

      <Widget
        sources={[
          "local",
          "camera",
          "facebook",
          "instagram",
          "image_search",
          "google_drive",
          "dropbox",
        ]} // set the sources available for uploading -> by default
        // all sources are available. More information on their use can be found at
        // https://cloudinary.com/documentation/upload_widget#the_sources_parameter

        resourceType={"auto"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
        cloudName={"dedg9uwnn"} // your cloudinary account cloud name.
        // Located on https://cloudinary.com/console/
        uploadPreset={"f2azyoh2"} // check that an upload preset exists and check mode is signed or unisgned
        buttonText={"ВЛАДИСЛАВ"} // default 'Upload Files'
        style={button} // inline styling only or style id='cloudinary_upload_button'
        folder={"Vladislav"} // set cloudinary folder name to send file
        cropping={true} // set ability to crop images -> default = true
        onSuccess={successCallBack}
        onFailure={failureCallBack}
        logging={true} // logs will be provided for success and failure messages,
        // set to false for production -> default = true
        use_filename={false} // tell Cloudinary to use the original name of the uploaded
        // file as its public ID -> default = true,
      />

      <Widget
        sources={[
          "local",
          "camera",
          "facebook",
          "instagram",
          "image_search",
          "google_drive",
          "dropbox",
        ]} // set the sources available for uploading -> by default
        // all sources are available. More information on their use can be found at
        // https://cloudinary.com/documentation/upload_widget#the_sources_parameter

        resourceType={"auto"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
        cloudName={"dedg9uwnn"} // your cloudinary account cloud name.
        // Located on https://cloudinary.com/console/
        uploadPreset={"f2azyoh2"} // check that an upload preset exists and check mode is signed or unisgned
        buttonText={"ВЪЗРАЖДАНЕ"} // default 'Upload Files'
        style={button} // inline styling only or style id='cloudinary_upload_button'
        folder={"Vuzrajdane"} // set cloudinary folder name to send file
        cropping={true} // set ability to crop images -> default = true
        onSuccess={successCallBack}
        onFailure={failureCallBack}
        logging={true} // logs will be provided for success and failure messages,
        // set to false for production -> default = true
        use_filename={false} // tell Cloudinary to use the original name of the uploaded
        // file as its public ID -> default = true,
      />

      <Widget
        sources={[
          "local",
          "camera",
          "facebook",
          "instagram",
          "image_search",
          "google_drive",
          "dropbox",
        ]} // set the sources available for uploading -> by default
        // all sources are available. More information on their use can be found at
        // https://cloudinary.com/documentation/upload_widget#the_sources_parameter

        resourceType={"auto"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
        cloudName={"dedg9uwnn"} // your cloudinary account cloud name.
        // Located on https://cloudinary.com/console/
        uploadPreset={"f2azyoh2"} // check that an upload preset exists and check mode is signed or unisgned
        buttonText={"ПЕЧАЛБИ"} // default 'Upload Files'
        style={button} // inline styling only or style id='cloudinary_upload_button'
        folder={"Pechalbi"} // set cloudinary folder name to send file
        cropping={true} // set ability to crop images -> default = true
        onSuccess={successCallBack}
        onFailure={failureCallBack}
        logging={true} // logs will be provided for success and failure messages,
        // set to false for production -> default = true
        use_filename={false} // tell Cloudinary to use the original name of the uploaded
        // file as its public ID -> default = true,
      />

      {imageReady ? (
        <div style={{ width: "300px", textAlign: "center", fontSize: "14px", padding: "20px"}}>
          <h4 style={{color: "#fff"}}>{`Снимката качена успешно в папка ${name}`}</h4>
          <img style={{ width: "100%" }} src={url} alt="mini-result" />
        </div>
      ) : null}
    </div>
  );
};

export default CloudinaryUploadWidget;
