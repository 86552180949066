import BonusDetails from "../../../components/bonus-details";
import bombataImage from "../../../images/bonuses/bombata.jpg";
import restoImage from "../../../images/bonuses/resto.jpg";
import cloverIcon from "../../../images/bonuses/clover.gif";
import PageWrapper from "../../../components/page-wrapper";
import styles from "./index.module.css";
import egyptImage from "../../../images/bonuses/egypt.jpg";
import moneyTrackingImage from "../../../images/bonuses/money-tracking.jpg";
import MiniBonus from "../../../components/mini-bonus";

const Resto = () => {
  return (
    <PageWrapper>
      <BonusDetails
        imgUrl={restoImage}
        imgAlt="Bonus-banner"
        title="Персонална бонус игра „БОНУС РЕСТО“"
        validIn="Важи за: всички зали Европа Бет"
        rulesTitle="Условия за участие в бонус играта:"
        icon={cloverIcon}
        iconAlt="cloverIcon"
        rule1="Всеки клиент реализирал загуба на игралните автомати над 200,00 / двеста/ лева за целия игрален ден получава правото на участие в бонус играта „БОНУС РЕСТО“, както следва:"
        rule2=" - При реализирана загуба от 200,00 /двеста/ до 300,00 /триста/ лева за игралния ден, клиентът получава правото на 20,00 /двадесет/ лева бонус премия."
        rule3=" - При реализирана загуба от 300,00 /триста/ до 500,00 /петстотин/ лева за игралния ден, клиентът получава правото на 30,00 /тридесет/ лева бонус премия."
        rule4=" - При реализирана загуба от 500,00 /петстотин/ до 1000,00 /хиляда/ лева за игралния ден, клиентът получава правото на 50,00 /петдесет/ лева бонус премия."
        rule5=" - При реализирана загуба от 1000,00 /хиляда/ до 2000,00 /две хиляди/ лева за игралния ден, клиентът получава правото на 100,00 /сто/ лева бонус премия."
        rule6=" - При реализирана загуба над 2000,00 /две хиляди/ лева за игралния ден, клиентът получава правото на 200,00 /двеста/ лева бонус премия."
        rule7="От спечелената бонус премия, клиентът може да се възползва в рамките на до седем дни, след реализираната от него загуба, но не по рано от следващият игрален ден. Бонус премиите са под формата на кредит, на което и да е игрално съоръжение, находящо се в игралната зала.
        "
        rightLinkName="БОМБАТА >>"
        rightLink="/bombata"
      />

      <div className={styles.shell}>
      <section className={styles.miniBonuses}>
          <MiniBonus
            imgUrl={bombataImage}
            articleTitle="БОНУС ИГРА „БОМБАТА“"
            address="Важи за: всички зали Европа Бет"
            pathName="/bombata"
          />

          <MiniBonus
            imgUrl={egyptImage}
            articleTitle="персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“"
            address="Важи за: всички зали Европа Бет "
            pathName="/egypt"
          />

          <MiniBonus
            imgUrl={moneyTrackingImage}
            articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „МЪНИ ТРАКИНГ“"
            address="Важи за: всички зали Европа Бет "
            pathName="/money-tracking"
          />
        </section>
      </div>
    </PageWrapper>
  );
};

export default Resto;
