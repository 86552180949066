import PageWrapper from "../../components/page-wrapper";
import Carousel from "../../components/carousel";
import Casinos from "../../components/casinos";
import Banner from "../../components/banner";
import GoogleMap from "../../components/google-maps";
import Background from "../../images/background-map.jpg";
import styles from "./index.module.css";
import Gallery from "../../components/gallery";
import { useEffect, useState } from "react";


const background = {
  backgroundImage: `url(${Background})`,
  width: "100%",
  backgroundSize: "cover no-repeat",
  padding: "40px 0",
  textAlign: "center",
  borderBottom: "2px solid #114780",
};

const fetchUri = `https://europabet.bg`;

const Home = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`${fetchUri}/api/pechalbi`)
      .then((res) => res.json())
      .then((data) => setItems(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <PageWrapper>
      <Carousel />
      <Casinos />      
      <Gallery images={items}
      title="изплатени печалби"/>
      <Banner />
      <div style={background}>
        <div className={styles.mapWrapper}>
          <GoogleMap
            title="gambling-halls"
            googleUrl="https://www.google.com/maps/d/embed?mid=1JZgk3_TMybs_MXd5F06_TO2vbuyfhb7v"
            width="1600"
            height="570"
            loading="lazy"
          />
        </div>
      </div>

    </PageWrapper>
  );
};

export default Home;
