import React from "react";
import styles from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlowingButton from "../glowing-button";

const CasinoArticle = ({
  image,
  imageAltData,
  title,
  address,
  facebookLink,
  casinoPresentationText,
  leftLink,
  rightLink,
  leftLinkName,
  rightLinkName,
}) => {
  return (
    <article className={styles.componentWrapper}>
      <div className={styles.articleImage}>
        <img src={image} alt={imageAltData} />
      </div>

      <section className={styles.articleWrapper}>
        <div className={styles.navigation}>
          <div className={styles.titles}>
            <h2>{title}</h2>
            <h3>{address}</h3>
          </div>

          <div className={styles.facebook}>
            <a href={facebookLink} rel="noreferrer" target="_blank">
              <FontAwesomeIcon size="2x" icon={["fab", "facebook-square"]} />{" "}
            </a>
          </div>
        </div>

        <div className={styles.casinoPresentation}>
          <p>{casinoPresentationText}</p>
        </div>

        <div className={styles.articleActions}>
            <div className={styles.customButton}>
              <GlowingButton title={leftLinkName} path={leftLink} />
            </div>

            <div className={styles.customButton}>
              <GlowingButton title={rightLinkName} path={rightLink} />
            </div>
        </div>
      </section>
    </article>
  );
};

export default CasinoArticle;
