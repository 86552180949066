import CasinoArticle from "../../components/casino-article";
import PageWrapper from "../../components/page-wrapper";
import styles from "./index.module.css";
import photoVuzrajdane from "../../images/vuzrajdane/vuzrajdane-main.jpg";
import VirtualWalk from "../../components/virtual-walk";
import backgroundImage from "../../images/background-map.jpg";
import GoogleMap from "../../components/google-maps";
import vuzrajdaneVirtualWalkPhoto from "../../images/vuzrajdane/vuzrajdaneVW.jpg";
import MiniBonus from "../../components/mini-bonus";
import restoImage from "../../images/bonuses/resto.jpg";
import egyptImage from "../../images/bonuses/egypt.jpg";
import moneyTrackingImage from "../../images/bonuses/money-tracking.jpg";
import Gallery from "../../components/gallery";
import { useEffect, useState } from "react";

const fetchUri = `https://europabet.bg`;

const Vuzrajdane = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`${fetchUri}/api/vuzrajdane`)
      .then((res) => res.json())
      .then((data) => setItems(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <PageWrapper>
      <section className={styles.sectionIntro}>
        <CasinoArticle
          image={photoVuzrajdane}
          imageAltData="PhotoVuzrajdane"
          title="EUROPABET Възраждане"
          address='Варна, ул."Владимир Висоцки" 2'
          facebookLink="https://facebook.com/europabet.vazrazhdane"
          casinoPresentationText="Най-новата зала на фирмата Европа Бет“ – Възраждане отваря врати
          на 26 Май 2021г. в гр. Варна, ж.к. „Възраждане“ I, ул.“Владимир
          Висоцки“№2 (до магазин „Май Маркет“). Залата разполага с добре
          обучен високо квалифициран персонал, който се грижи за доброто
          настроение на своите клиенти, както и с най-новата техника от
          игралната индустрия. Атрактивните джакпоти, персоналните бонус
          игри, безплатния паркинг, безплатните напитки и куп други екстри
          карат посетителите да се чувстват уютно и комфортно в игралната
          залата."
          leftLink="vladislav"
          rightLink="botevata"
          leftLinkName="<< EUROPABET Владислав"
          rightLinkName="EUROPABET Ботевата >>"
        />

        <div className={styles.shell}>
          <section className={styles.miniBonuses}>
            <MiniBonus
              imgUrl={restoImage}
              articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „БОНУС РЕСТО“"
              address="Важи за: всички зали Европа Бет"
              pathName="/resto"
            />

            <MiniBonus
              imgUrl={egyptImage}
              articleTitle="персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“"
              address="Важи за: всички зали Европа Бет "
              pathName="/egypt"
            />

            <MiniBonus
              imgUrl={moneyTrackingImage}
              articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „МЪНИ ТРАКИНГ“"
              address="Важи за: всички зали Европа Бет"
              pathName="/money-tracking"
 
            />
          </section>
        </div>

        <Gallery images={items} />

        <section className={styles.googleData} 
        style={{  
          backgroundSize: "cover no-repeat",
          backgroundImage: `url(${backgroundImage})`}}>

          <div className={styles.googleContainer}>
            <VirtualWalk
              image={vuzrajdaneVirtualWalkPhoto}
              googleVirtualLink="https://goo.gl/maps/2kSqVF2q2gezBELm7"
            />

            <div className={styles.googleMap}>
              <GoogleMap
                title="Vuzrajdane"
                googleUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d363.3405222358621!2d27.876656892926285!3d43.23624229208164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a455dcfd24bac3%3A0x37993b490df5199d!2z0JjQs9GA0LDQu9C90LAg0LfQsNC70LAg0LfQsCDRhdCw0LfQsNGA0YLQvdC4INC40LPRgNC4ICLQldCy0YDQvtC_0LAg0JHQtdGCIi0g0JLRitC30YDQsNC20LTQsNC90LU!5e0!3m2!1sen!2sbg!4v1633968425800!5m2!1sen!2sbg"
                width="740"
                height="493"
              />
            </div>
          </div>
        </section>
      </section>
    </PageWrapper>
  );
};

export default Vuzrajdane;
