import "./styles/App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { LanguageProvider } from "./containers/language.js";

function App(props) {

  return (
      <LanguageProvider>
      {props.children}
      </LanguageProvider>
  );
}

export default App;
