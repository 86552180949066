import { useState, createContext, useContext } from "react";
import { dictionaryList } from "../languages";

export const LanguageContext = createContext({
  userLanguage: "bg",
  dictionary: dictionaryList.bg,
});

export function LanguageProvider({ children }) {
  const defaultLanguage = window.localStorage.getItem("rcml-lang");
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || "bg");

  const provider = {
    userLanguage,
    dictionary: dictionaryList[userLanguage],
    userLanguageChange: () => {
      let newLanguage = ''
      const currentLanguage = window.localStorage.getItem("rcml-lang")

      if (!currentLanguage) {
        newLanguage = 'en'
      } else if (currentLanguage === 'en') {
        newLanguage = 'bg'
      } else {
        newLanguage = 'en'
      }

      setUserLanguage(newLanguage);
      window.localStorage.setItem("rcml-lang", newLanguage);
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
}

export function Text({ tid }) {
  const languageContext = useContext(LanguageContext);

  return languageContext.dictionary[tid] || tid;
}
