import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import jackpotPhoto from "../../images/united_jackpot.jpg";
import bonusRestoImage from "../../images/bonus_banner.jpg";
import egyptBonusImage from "../../images/egypt_banner.jpg";
import moneyTrackingImage from "../../images/money_banner.jpg";
import bombataBonusImage from '../../images//bonuses/bombata.jpg'

const CarouselSlider = () => {
  return (
    <Carousel
      autoPlay={true}
      showThumbs={false}
      infiniteLoop={true}
      stopOnHover={true}
      showStatus={false}
    >
      <div>
        <img src={jackpotPhoto} alt="jackpotPhoto" />
      </div>
      <div>
        <img src={bombataBonusImage} alt="jackpotPhoto" />
      </div>
      <div>
        <img src={bonusRestoImage} alt="jackpotPhoto" />
      </div>
      <div>
        <img src={egyptBonusImage} alt="jackpotPhoto" />
      </div>
      <div>
        <img src={moneyTrackingImage} alt="jackpotPhoto" />
      </div>
    </Carousel>
  );
};

export default CarouselSlider;
