import BonusDetails from "../../../components/bonus-details";
import moneyTrackingImage from "../../../images/bonuses/money-tracking.jpg";
import tresureIcon from "../../../images/bonuses/egypt-icon.jpg";
import PageWrapper from "../../../components/page-wrapper";
import styles from "./index.module.css";
import restoImage from "../../../images/bonuses/resto.jpg";
import egyptImage from "../../../images/bonuses/egypt.jpg";
import MiniBonus from "../../../components/mini-bonus";
import bombataImage from "../../../images/bonuses/bombata.jpg";


const MoneyTracking = () => {
  return (
    <PageWrapper>
      <BonusDetails
        imgUrl={moneyTrackingImage}
        imgAlt="moneyTrack-banner"
        title="Персонална бонус игра „МЪНИ ТРАКИНГ“"
        validIn="Важи за: всички зали Европа Бет "
        rulesTitle="Право на участие имат всички клиенти притежаващи индивидуални RFID(чип) карти и участващи в игрите."
        icon={tresureIcon}
        iconAlt="treasureIcon"
        rule2="На база разиграната от клиента сума на игралните автомати в залата, включени в системата MCMS, клиентът получава бонус точки на стойност 0.5 %. При събиране на 1 000 /хиляда/ бонус точки клиентът получава премия 10 /десет/ лева. Спечелената премия се зарежда от крупието под формата на кредит на игрален автомат, посочен от клиента."
        showIcon1="false"
        showIcon2="false"
        showIcon3="false"
        showIcon4="false"
        showIcon5="false"
        leftLinkName="<< ЕГИПЕТСКО СЪКРОВИЩЕ"
        leftLink="/egypt"
      />

      <div className={styles.shell}>
      <section className={styles.miniBonuses}>
          <MiniBonus
            imgUrl={restoImage}
            articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „БОНУС РЕСТО“"
            address="Важи за: всички зали Европа Бет"
            pathName="/resto"
          />

        <MiniBonus
          imgUrl={bombataImage}
          articleTitle="БОНУС ИГРА „БОМБАТА“"
          address="Важи за: всички зали Европа Бет"
          pathName="/bombata"
          />

          <MiniBonus
            imgUrl={egyptImage}
            articleTitle="персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“"
            address="Важи за: всички зали Европа Бет "
            pathName="/egypt"
          />

        </section>
      </div>
    </PageWrapper>
  );
};

export default MoneyTracking;
