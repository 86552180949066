import styles from "./index.module.css";
import "../../components/fontawesomeicons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/europa-logo.png";
import { Link, NavLink } from "react-router-dom";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
// import LanguageSelector from "../../components/language/languageSelector.js";
import { Text } from "../../containers/language";

const Header = () => {
  return (
    <div>
      <nav className={styles.topnav}>
        <div className={styles.pageWrapper}>
          <Nav.Link className={styles.logo} as={Link} to="/">
            <img src={logo} alt="Logo" />
          </Nav.Link>

          <Navbar className={styles.mobileNavbar} variant={"dark"} expand="lg">
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav variant="pills">
                <div className={styles.social}>
        
                  <Nav.Item>
                    <Nav.Link eventKey="link1" as={Link} to="/">
                      <Text tid="home" />
                    </Nav.Link>
                  </Nav.Item>

                  <NavDropdown
                    menuVariant="dark"
                    title={<Text tid="gammingHalls" />}
                    id="nav-dropdown"
                  >
                    <NavDropdown.Item
                      style={{
                        "border-bottom": "2px solid #fff",
                      }}
                    >
                      <Nav.Link as={NavLink} to="/botevata">
                        Ботевата
                      </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      style={{ "border-bottom": "2px solid #fff" }}
                    >
                      <Nav.Link as={NavLink} to="/vladislav">
                        Владислав
                      </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <Nav.Link as={NavLink} to="/vuzrajdane">
                        Възраждане
                      </Nav.Link>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Item>
                    <Nav.Link eventKey="link2" as={NavLink} to="/bonuses">
                      {<Text tid="bonus" />}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link style={{width: "max-content"}} eventKey="link3" as={NavLink} to="/about">
                    {<Text tid="aboutUs" />}
                    </Nav.Link>
                  </Nav.Item>
                </div>
                <div className={styles.social}>
                  <Nav.Item>
                    <a href="mailto: europabet.bg@gmail.com">
                      <FontAwesomeIcon
                        color="#f3b359"
                        size="2x"
                        icon="envelope"
                      />
                    </a>
                  </Nav.Item>

                  <Nav.Item>
                    <NavDropdown
                      title={
                        <FontAwesomeIcon
                          size="2x"
                          icon={["fab", "facebook-square"]}
                        />
                      }
                      menuVariant="dark"
                    >
                      <NavDropdown.Item
                        style={{
                          "border-bottom": "2px solid #fff",
                        }}
                        target="_blank"
                        href="https://facebook.com/casinoeuropebet"
                      >
                        Ботевата
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        style={{
                          "border-bottom": "2px solid #fff",
                        }}
                        target="_blank"
                        href="https://bg-bg.facebook.com/%D0%95%D0%B2%D1%80%D0%BE%D0%BF%D0%B0-%D0%91%D0%B5%D1%82-%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D1%81%D0%BB%D0%B0%D0%B2-101602235504672/"
                      >
                        Владислав
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        target="_blank"
                        href="https://facebook.com/europabet.vazrazhdane"
                      >
                        Възраждане
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav.Item>

                  {/* <Nav.Item>
                    <LanguageSelector />
                  </Nav.Item>  */}
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </nav>
      <div className={styles["decoration-lines"]}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default Header;
