import bg from './bg.json'
import en from './en.json'

export const dictionaryList = { bg, en }

export const languageOptions = {
    bg: 'Български',
    en: 'English'
}

