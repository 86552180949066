import CasinoArticle from "../../components/casino-article";
import PageWrapper from "../../components/page-wrapper";
import PhotoBotevata from "../../images/botevata-hall.jpg";
import styles from "./index.module.css";
import VirtualWalk from "../../components/virtual-walk";
import backgroundImage from "../../images/background-map.jpg";
import GoogleMap from "../../components/google-maps";
import botevataVirtualWalkPhoto from "../../images/botevata/botevataVW.jpg";
import MiniBonus from "../../components/mini-bonus";
import egyptImage from "../../images/bonuses/egypt.jpg";
import moneyTrackingImage from "../../images/bonuses/money-tracking.jpg";
import restoImage from "../../images/bonuses/resto.jpg";
import Gallery from "../../components/gallery";
import { useEffect, useState } from "react";

const fetchUri = `https://europabet.bg`;

const Botevata = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`${fetchUri}/api/botevata`)
      .then(res => res.json())
      .then(data => setItems(data))
      .catch(console.error);
  }, []);

  return (
    <PageWrapper>
      <section className={styles.sectionIntro}>
        <CasinoArticle
          image={PhotoBotevata}
          imageAltData="PhotoBotevata"
          title="EUROPABET Ботевата"
          address='Варна, ул."Фр. Жулио Кюри" 48'
          facebookLink="https://facebook.com/casinoeuropebet"
          casinoPresentationText="С нова техника, добре обучен и подбран персонал игралната зала,
           находяща се в гр. Варна, ул.“Фредерик Жолио Кюри“ №48 (в района на Колхозен пазар), 
           допринася за доброто настроение на своите безброй клиенти и почитатели с атрактивните джакпоти и неспирните бонуси.
           Обслужването е на най-високо ниво, благодарение на специално обучения персонал на фирмата."
          leftLink="vuzrajdane"
          rightLink="vladislav"
          leftLinkName="<< EUROPABET Възраждане"
          rightLinkName="EUROPABET Владислав >>"
        />

<div className={styles.shell}>
          <section className={styles.miniBonuses}>
            <MiniBonus
              imgUrl={restoImage}
              articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „БОНУС РЕСТО“"
              address="Важи за: всички зали Европа Бет"
              pathName="/resto"
            />

            <MiniBonus
              imgUrl={egyptImage}
              articleTitle="персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“"
              address="Важи за: всички зали Европа Бет "
              pathName="/egypt"
            />

            <MiniBonus
              imgUrl={moneyTrackingImage}
              articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „МЪНИ ТРАКИНГ“"
              address="Важи за: всички зали Европа Бет "
              pathName="/money-tracking"
 
            />
          </section>
        </div>

          <Gallery images={items} />

        <section className={styles.googleData} 
        style={{  
          backgroundSize: "cover no-repeat",
          backgroundImage: `url(${backgroundImage})`}}>
          <div className={styles.googleContainer}>

            <VirtualWalk
              image={botevataVirtualWalkPhoto}
              googleVirtualLink="https://goo.gl/maps/hhTmP8KqUkSLAP9u5"
            />

            <div className={styles.googleMap}>
              <GoogleMap
                title="Botevata"
                googleUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5815.800590957088!2d27.898749025531238!3d43.21158053607254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a45479771f0283%3A0x6c522404ec35b5b!2z0JjQs9GA0LDQu9C90LAg0LfQsNC70LAg0LfQsCDRhdCw0LfQsNGA0YLQvdC4INC40LPRgNC4ICLQldCy0YDQvtC_0LAg0JHQtdGCIiAtINCS0LvQsNC00LjRgdC70LDQsg!5e0!3m2!1sbg!2sbg!4v1635258882805!5m2!1sbg!2sbg"
                width="740"
                height="493"
              />
            </div>
          </div>
        </section>
      </section>
    </PageWrapper>
  );
};

export default Botevata;
