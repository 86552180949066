import React from "react";
import Background from "../../images/background-main.png";
import styles from "./index.module.css";
import SingleArticle from "../single-article";
import botevata from "../../images/botevata/botevata.jpg";
import vladislav from "../../images/vladislav/vladislav.jpg";
import vuzrajdane from "../../images/vuzrajdane/vuzrajdane.jpg";
import { Container, Row, Col } from "react-bootstrap";

const mainStyle = {
  backgroundImage: `url(${Background})`,
  width: "100%",
  backgroundSize: "cover no-repeat",
};

const Casinos = () => {
  return (
    <main style={mainStyle}>
      <div className={styles.pageWrapper}>
        <Container fluid>
          <Row className={styles.container}>
            <Col md={12} lg={4} className='text-center'>
              <SingleArticle
                articleTitle="EUROPABET Ботевата"
                imgUrl={botevata}
                altName="botevata-photo"
                address='Варна, "ул.Фр. Жулио Кюри" 48'
                pathName="botevata"
              />
            </Col>

            <Col md={12} lg={4} className='text-center'>
              <SingleArticle
                articleTitle="EUROPABET Владислав"
                imgUrl={vladislav}
                altName="vladislav-photo"
                address='Варна, бул."Владислав Варненчик" 130'
                pathName="vladislav"
              />
            </Col>

            <Col md={12} lg={4} className='text-center'>
              <SingleArticle
                articleTitle="EUROPABET Възраждане"
                imgUrl={vuzrajdane}
                altName="vuzrajdane-photo"
                address='Варна, ул."Владимир Висоцки" 2'
                pathName="vuzrajdane"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
};

export default Casinos;
