import Background from "../../images/bonuses/background-bonus.png";
import styles from "./index.module.css";
import GlowingButton from "../../components/glowing-button";

const background = {
  backgroundImage: `url(${Background})`,
  width: "100%",
  backgroundSize: "cover no-repeat",
  padding: "40px 0 0 0",
};

const BonusDetails = ({
  imgUrl,
  imgAlt,
  date,
  title,
  validIn,
  rulesTitle,
  icon,
  iconAlt,
  rule1,
  rule2,
  rule3,
  rule4,
  rule5,
  rule6,
  rule7,
  rule8,
  showIcon1,
  showIcon2,
  showIcon3,
  showIcon4,
  showIcon5,
  leftLinkName,
  leftLink,
  rightLinkName,
  rightLink,
}) => {
  return (
      <div style={background}>
        <div className={styles.pageWrapper}>
          <article className={styles.bonusWrapper}>
            <div className={styles.imgContainer}>
              <img src={imgUrl} alt={imgAlt} />
            </div>

            <div className={styles.subTitle}>
              <p>{date}</p>
              <p>{validIn}</p>
            </div>

            <div className={styles.title}>
              <h1>{title}</h1>
            </div>

            <div className={styles.rules}>
              <p>{rulesTitle}</p>
              <p>{rule1}</p>
              <p>
                <img style={{display: showIcon1 ? 'none' : 'inline-block'}} className={styles.icon} src={icon} alt={iconAlt}/>{rule2}
              </p>
              <p>
              <img style={{display: showIcon2 ? 'none' : 'inline-block'}} className={styles.icon} src={icon} alt={iconAlt} /> {rule3}
              </p>
              <p>
                <img style={{display: showIcon3 ? 'none' : 'inline-block'}} className={styles.icon} src={icon} alt={iconAlt} /> {rule4}
              </p>
              <p>
                <img style={{display: showIcon4 ? 'none' : 'inline-block'}} className={styles.icon} src={icon} alt={iconAlt} /> {rule5}
              </p>
              <p>
                <img style={{display: showIcon5 ? 'none' : 'inline-block'}} className={styles.icon} src={icon} alt={iconAlt} /> {rule6}
              </p>
              <p>{rule7}</p>
              <p>{rule8}</p>
            </div>

            <div className={styles.articleActions}>
              <div style={{opacity: leftLinkName ? '1' : '0'}} className={styles.customButton}>
                <GlowingButton title={leftLinkName} path={leftLink} />
              </div>

              <div style={{display: rightLinkName ? 'inline-block' : 'none'}} className={styles.customButton}>
                <GlowingButton title={rightLinkName} path={rightLink} />
              </div>
            </div>
          </article>
        </div>
      </div>
  );
};

export default BonusDetails;
