import styles from "./index.module.css";

const VirtualWalk = ({ image, imageAlt, googleVirtualLink }) => {
  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src={image} alt={imageAlt} />
      </div>

      <div className={styles.button}>
        <a href={googleVirtualLink} rel="noreferrer" target="_blank">Виртуална разходка</a>
      </div>
    </div>
  );
};

export default VirtualWalk;
