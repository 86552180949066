import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./pages/home";
import Botevata from "./pages/botevata";
import Vladislav from "./pages/vladislav";
import Vuzrajdane from "./pages/vuzrajdane";
import About from "./pages/about";
import Bonuses from "./pages/bonuses";
import Resto from "./pages/bonuses/allBonuses/resto";
import Egypt from "./pages/bonuses/allBonuses/egypt";
import MoneyTracking from "./pages/bonuses/allBonuses/moneyTracking";
import Bombata from "./pages/bonuses/allBonuses/bombata";
import Admin from "./pages/admin";

const Navigation = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/bonuses" component={Bonuses} />
        <Route path="/resto" component={Resto} />
        <Route path="/egypt" component={Egypt} />
        <Route path="/money-tracking" component={MoneyTracking} />
        <Route path="/game-halls" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/botevata" component={Botevata} />
        <Route path="/vladislav" component={Vladislav} />
        <Route path="/vuzrajdane" component={Vuzrajdane} />
        <Route path="/admin" component={Admin} />
        <Route path="/" component={Bombata}/>
      </Switch>
    </BrowserRouter>
  );
};

export default Navigation;
