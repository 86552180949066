import PageWrapper from "../../components/page-wrapper";
import CloudinaryUploadWidget from "../../components/cloudinary-widget";
import Protect from "react-app-protect";
import "react-app-protect/dist/index.css";

const Admin = () => {
  return (
    <Protect
      boxTitle="Админ панела е защитен с парола"
      sha512="285D564D65795E4F7A3363E904D34BBD61630BD5FEDFE1F2F6C112A37EEA521B3A2BB593CA1D6EB181D25ED404D52538424AE63BB24E594D057EB94D97EF952D"
      inputPlaceholder="Парола"
      buttonLabel="потвърди"
    >
      <PageWrapper>
        <div>
          <CloudinaryUploadWidget />
        </div>
      </PageWrapper>
    </Protect>
  );
};

export default Admin;
