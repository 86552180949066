import React from "react";
import styles from "./index.module.css";
import GlowingButton from "../glowing-button";

const SingleArticle = ({
  imgUrl,
  articleTitle,
  address,
  altName,
  pathName,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img className={styles.img} src={imgUrl} alt={altName} />
      </div>

      <p className={styles.title}>{articleTitle}</p>
      <span className={styles.address}>{address}</span>

      <div className={styles.customButton}>
      <GlowingButton title="НАУЧИ ПОВЕЧЕ" path={pathName} />
      </div>
    </div>
  );
};

export default SingleArticle;
