import React from "react";
import PageWrapper from "../../components/page-wrapper";
import CasinoArticle from "../../components/casino-article";
import styles from "./index.module.css";
import vladislavPhoto from "../../images/vladislav/vladislav-main.jpg";
import vladislavVirtualWalkPhoto from "../../images/vladislav/vladislav-virutal-walk.jpg";
import VirtualWalk from "../../components/virtual-walk";
import backgroundImage from "../../images/background-map.jpg";
import GoogleMap from "../../components/google-maps";
import MiniBonus from "../../components/mini-bonus";
import restoImage from "../../images/bonuses/resto.jpg";
import egyptImage from "../../images/bonuses/egypt.jpg";
import moneyTrackingImage from "../../images/bonuses/money-tracking.jpg";
import Gallery from "../../components/gallery";
import { useEffect, useState } from "react";

const fetchUri = `https://europabet.bg`

const Vladislav = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`${fetchUri}/api/vladislav`)
      .then(res => res.json())
      .then((data) => setItems(data))
      .catch((error) => console.log(error));
  }, []);


  return (
    <PageWrapper>
      <section className={styles.sectionIntro}>
        <CasinoArticle
          image={vladislavPhoto}
          imageAltData="VladislavPhoto"
          title="EUROPABET Владислав"
          address='Варна, бул."Владислав Варненчик" 130'
          facebookLink="https://bg-bg.facebook.com/%D0%95%D0%B2%D1%80%D0%BE%D0%BF%D0%B0-%D0%91%D0%B5%D1%82-%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D1%81%D0%BB%D0%B0%D0%B2-101602235504672/"
          casinoPresentationText="Втората игрална зала на фирмата „Европа Бет“ – Владислав отваря
          врати през м. Януари 2016г. с адрес гр. Варна, бул. „Владислав
          Варненчик“№130 (пресечката на ул.“Патриарх Евтимий и бул.“Вл.
          Варненчик“). За доброто настроение на своите клиенти също се грижи
          висококвалифициран персонал. Залата предлага най-високо ниво, благодарение на специално обучения персонал на фирмата."
          leftLink="botevata"
          rightLink="vuzrajdane"
          leftLinkName="<< EUROPABET Ботевата"
          rightLinkName="EUROPABET Възраждане >>"
        />

<div className={styles.shell}>
          <section className={styles.miniBonuses}>
            <MiniBonus
              imgUrl={restoImage}
              articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „БОНУС РЕСТО“"
              address="Важи за: всички зали Европа Бет"
              pathName="/resto"
            />

            <MiniBonus
              imgUrl={egyptImage}
              articleTitle="персонална бонус игра „ЕГИПЕТСКО СЪКРОВИЩЕ“"
              address="Важи за: всички зали Европа Бет "
              pathName="/egypt"
            />

            <MiniBonus
              imgUrl={moneyTrackingImage}
              articleTitle="ПЕРСОНАЛНА БОНУС ИГРА „МЪНИ ТРАКИНГ“"
              address="Важи за: всички зали Европа Бет "
              pathName="/money-tracking"
 
            />
          </section>
        </div>

          <Gallery images={items} />

          <section className={styles.googleData} 
        style={{  
          backgroundSize: "cover no-repeat",
          backgroundImage: `url(${backgroundImage})`}}>
      
          <div className={styles.googleContainer}>
            <VirtualWalk
              image={vladislavVirtualWalkPhoto}
              googleVirtualLink="https://goo.gl/maps/HqcUNijVPnWaxRkQ9"
            />

            <div className={styles.googleMap}>
              <GoogleMap
                title="Vladislav"
                googleUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5815.799115563909!2d27.898749025020596!3d43.21159600821411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40a45479771f0283%3A0x6c522404ec35b5b!2z0JjQs9GA0LDQu9C90LAg0LfQsNC70LAg0LfQsCDRhdCw0LfQsNGA0YLQvdC4INC40LPRgNC4ICLQldCy0YDQvtC_0LAg0JHQtdGCIiAtINCS0LvQsNC00LjRgdC70LDQsg!5e0!3m2!1sbg!2sbg!4v1635258997262!5m2!1sbg!2sbg"
                width="740"
                height="493"
              />
            </div>
          </div>
        </section>
      </section>
    </PageWrapper>
  );
};

export default Vladislav;
