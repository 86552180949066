import Header from "../../components/header";
import Footer from "../../components/footer";

const PageWrapper = (props) => {

  return (
    <div>
      <Header />
      <main id="main">{props.children}</main>
      <Footer />
    </div>
  );
};

export default PageWrapper;
