import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faMoneyBillWave,
  faClock
} from "@fortawesome/free-solid-svg-icons";

library.add(faFacebookSquare, faEnvelope, faPhone, faMapMarkerAlt, faMoneyBillWave, faClock);
